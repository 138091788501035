.metadata-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1100;
}

.metadata-modal-content {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    width: 600px;
    max-width: 90%;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    position: relative;
}

.metadata-modal-content h3 {
    margin-bottom: 15px;
}

.metadata-text {
    background: #f4f4f4;
    padding: 10px;
    max-height: 400px;
    overflow-y: auto;
    white-space: pre-wrap;
    font-size: 14px;
    border: 1px solid #ddd;
}

.close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
}
