/* HomePage General Styles */
.home-page {
    padding: 20px;
    max-width: 100%;
    margin: 0 auto;
    color: #333;
    background-color: #f3f4f6;
    position: relative;
    z-index: 1; /* Ensure the home page has a stacking context */
}

.sections-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 15px;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    position: relative; /* Establish stacking context for the grid */
    z-index: 1; /* Ensure tooltips are above the grid */
}

.section-card {
    background: rgb(241, 241, 241);
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 10px;
    text-align: center;
    cursor: pointer;
    transition: transform 0.2s, box-shadow 0.2s;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 140px;
    height: 140px;
    position: relative;
    z-index: 2; /* Ensure it is above the grid but below tooltips */
}

.section-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.15);
}

.section-icon {
    width: 80px;
    /*height: 80px;*/
    flex-shrink: 0;
}

.section-name {
    font-family: "Times New Roman", Times, serif;
    font-size: 1rem;
    font-weight: bold;
    color: #333;
    margin-top: 10px;
    text-align: center;
}

/* Disabled Card Styling */
.disabled-card {
    position: relative;
    cursor: not-allowed;
    opacity: 0.5; /* Visual indication for disabled cards */
    z-index: 2; /* Ensure it doesn't overlay tooltips */
}






/* Debugging high-z-index to ensure no other element interferes */
* {
    z-index: auto; /* Prevent other elements from unnecessarily overriding */
}
/* Tooltip Styling */
.tooltip {
    position: fixed; /* Fixed to break out of parent hierarchy */
    background-color: rgba(0, 0, 0, 0.95); /* Fully opaque dark background */
    color: #fff;
    padding: 8px 12px;
    border-radius: 4px;
    font-size: 0.9rem;
    white-space: nowrap;
    z-index: 9999; /* Ensure it's above all other elements */
    pointer-events: none; /* Prevent interference with user interactions */
    opacity: 1; /* Ensure visibility */
    transform: translate(-50%, -10px); /* Centered above the card */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.tooltip::after {
    content: "";
    position: absolute;
    bottom: -5px;
    left: 50%;
    transform: translateX(-50%);
    border-width: 5px;
    border-style: solid;
    border-color: rgba(0, 0, 0, 0.95) transparent transparent transparent;
}

/* Only allow hover effect for disabled cards */
.disabled-card:hover .tooltip {
    opacity: 1;
    transform: translate(-50%, -20px);
}
