/* PreviewModal.css */

.preview-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.preview-modal-content {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    width: 600px;
    max-width: 90%;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    position: relative;
    max-height: 80vh;
    overflow-y: auto;
    text-align: center;
}

.preview-modal-content h3 {
    margin-bottom: 15px;
    font-size: 1.25rem;
}

.preview-container {
    border: 1px solid #ddd;
    padding: 10px;
    text-align: left;
    margin-top: 10px;
}

.preview-container pre {
    white-space: pre-wrap;
    font-size: 14px;
    line-height: 1.4;
    margin: 0;
}

.preview-container img {
    max-width: 100%;
    height: auto;
    display: block;
    margin: 0 auto;
}

.close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 1.25rem;
    color: #999;
    cursor: pointer;
}

.close-button:hover {
    color: #333;
}
