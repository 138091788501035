/* src/pages/StatsMeetings.css */

/* Modal Overlay */
.st-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    font-size: 0.95rem;
}

/* Modal Content */
.st-modal-content {
    background: #fff;
    width: 95%;
    max-width: 1200px;
    max-height: 90%;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    overflow: hidden; /* Ensures content doesn't spill out */
    box-shadow: 0 4px 20px rgba(0,0,0,0.3);
}

.st-modal-header {
    background: #f9f9f9;
    padding: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ddd;
}

.st-modal-header h2 {
    margin: 0;
    font-size: 1.4rem;
    font-weight: bold;
}

.st-modal-header .st-modal-close-btn {
    background: none;
    border: none;
    font-size: 1.4rem;
    cursor: pointer;
    color: #333;
    padding: 4px;
}

.st-modal-body {
    padding: 20px;
    overflow-y: auto;
    flex: 1;
}

/* Filters Section */
.st-filters {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-bottom: 20px;
}

.st-filter-row {
    display: flex;
    gap: 30px;
    flex-wrap: wrap;
}

.st-filter-group label {
    font-weight: bold;
    display: block;
    margin-bottom: 5px;
    font-size: 0.95rem;
}

.st-filter-group select, .st-filter-group input[type="date"] {
    padding: 6px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 0.9rem;
}

/* Overall Stats Section */
.st-section {
    margin-bottom: 30px;
}

.st-section h3 {
    margin-bottom: 15px;
    font-size: 1.2rem;
    border-bottom: 1px solid #eee;
    padding-bottom: 5px;
    color: #333;
}

.st-metrics {
    display: flex;
    gap: 20px;
    margin-top: 10px;
}

.st-metric {
    background: #f7f7f7;
    border-radius: 4px;
    padding: 20px;
    text-align: center;
    flex: 1;
    border: 1px solid #ddd;
}

.st-metric-value {
    font-size: 2rem;
    font-weight: bold;
    display: block;
    margin-bottom: 8px;
    color: #333;
}

.st-metric-label {
    font-size: 0.9rem;
    color: #555;
}

/* Monthly Controls (Export and Pagination) */
.monthly-controls {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
    margin-bottom: 15px;
}

/* Export CSV Button */
.export-csv-btn {
    background-color: #17a2b8;
    color: white;
    border: none;
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-size: 0.9rem;
}

.export-csv-btn:hover {
    background-color: #138496;
}

/* Pagination Controls */
.pagination-controls {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.pagination-controls button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-size: 0.9rem;
}

.pagination-controls button:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
}

.pagination-controls button:not(:disabled):hover {
    background-color: #0056b3;
}

.pagination-controls span {
    font-size: 1rem;
    color: #333;
}

/* Chart Container */
.st-chart-container {
    position: relative;
    width: 100%;
    max-width: 800px; /* Ensures the chart doesn't exceed this width */
    height: 500px;
    margin: 0 auto; /* Centers the chart container */
    overflow: hidden;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 15px;
    background: #fafafa;
}

.st-chart-container canvas {
    width: 100% !important;
    height: 100% !important;
}

/* Detailed Monthly Table */
.st-stats-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
    font-size: 0.9rem;
}

.st-stats-table th,
.st-stats-table td {
    border: 1px solid #ccc;
    padding: 8px;
    text-align: center;
}

.st-stats-table th {
    background-color: #f2f2f2;
    color: black; /* Set table header font color to black */
}

/* Inner Tables (e.g., Attendee Modal) */
.st-inner-table th,
.st-inner-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: center;
}

.st-inner-table th {
    background-color: #f2f2f2;
    color: black; /* Set inner table header font color to black */
}

.st-inner-table tr:nth-child(even) {
    background-color: #f9f9f9;
}

.st-inner-table tr:hover {
    background-color: #f1f1f1;
}

/* Loading Spinner */
.loading-spinner {
    text-align: center;
    font-size: 1.2rem;
    color: #555;
    margin-top: 50px;
}

/* Error Message */
.error-message {
    color: red;
    text-align: center;
    margin-top: 20px;
    font-size: 1rem;
}

/* Sankey Modal Styles */
.sankey-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1100;
}

/* Sankey Modal Content */
.sankey-modal-content {
    background: #fff;
    width: 80%;
    max-width: 1200px;
    max-height: 90%;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.3);
    display: flex;
    flex-direction: column;
}

.sankey-modal-header {
    background: #f9f9f9;
    padding: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ddd;
}

.sankey-modal-header h2 {
    margin: 0;
    font-size: 1.4rem;
}

.sankey-modal-header button {
    background: none;
    border: none;
    font-size: 1.4rem;
    cursor: pointer;
}

.sankey-modal-body {
    padding: 20px;
    flex: 1;
    overflow-y: auto;
}

.sankey-controls {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 10px;
}

.sankey-controls button {
    margin-right: 10px;
    padding: 8px 12px;
    background-color: #004182;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 0.9rem;
}

.sankey-controls button:hover {
    background-color: #002f5f;
}

.sankey-save-button {
    background-color: #28a745;
    color: white;
    border: none;
    padding: 6px 12px;
    border-radius: 4px;
    cursor: pointer;
    margin-left: 10px;
    transition: background-color 0.3s ease;
}

.sankey-save-button:hover {
    background-color: #218838;
}

/* Visitor Sankey Button */
.visitor-sankey-btn {
    background-color: #6f42c1;
    color: white;
    border: none;
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.visitor-sankey-btn:hover {
    background-color: #5a32a3;
}

/* Sankey Modal Save Button */
.sankey-modal-header .sankey-save-button {
    background-color: #007bff !important; /* Ensure it overrides other styles */
    color: #fff;
    border: none;
    padding: 8px 12px;
    border-radius: 4px;
    cursor: pointer;
    margin-left: 10px;
    font-size: 15px;
    transition: background-color 0.3s, transform 0.2s;
}

.sankey-modal-header .sankey-save-button:hover {
    background-color: #0056b3 !important;
    transform: scale(1.05);
}

.sankey-modal-header .sankey-save-button:active {
    background-color: #003d80 !important;
    transform: scale(0.95);
}

/* Attendee Modal Styles */
.attendee-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2000;
}

/* Attendee Modal Content */
.attendee-modal-content {
    background: #fff;
    width: 90%;
    max-width: 600px;
    border-radius: 10px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    overflow: hidden;
    position: relative;
}

/* Modal Header */
.attendee-modal-header {
    background: #004182;
    color: #fff;
    padding: 15px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 2px solid #003366;
}

.attendee-modal-header h2 {
    margin: 0;
    font-size: 1.2rem;
}

.attendee-modal-header button {
    background: none;
    border: none;
    color: #fff;
    font-size: 1.4rem;
    cursor: pointer;
}

/* Modal Body */
.attendee-modal-body {
    padding: 20px;
    max-height: 400px;
    overflow-y: auto;
}

/* Inner Tables (e.g., Attendee Modal) */
.st-inner-table {
    width: 100%;
    border-collapse: collapse;
    font-size: 0.9rem;
    margin-top: 10px;
}

.st-inner-table th {
    background-color: #f2f2f2;
    color: black; /* Set inner table header font color to black */
}

.st-inner-table td {
    border: 1px solid #ddd;
    padding: 10px;
}

.st-inner-table tr:nth-child(even) {
    background-color: #f9f9f9;
}

.st-inner-table tr:hover {
    background-color: #f1f1f1;
}

/* Modal Footer */
.attendee-modal-footer {
    background: #f9f9f9;
    padding: 10px 20px;
    text-align: right;
    border-top: 2px solid #ddd;
}

.attendee-modal-footer button {
    background: #004182;
    color: #fff;
    border: none;
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 0.9rem;
    transition: background 0.3s;
}

.attendee-modal-footer button:hover {
    background: #003366;
}

/* Sankey Modal Save Button */
.sankey-save-button {
    background-color: #28a745;
    color: white;
    border: none;
    padding: 6px 12px;
    border-radius: 4px;
    cursor: pointer;
    margin-left: 10px;
    transition: background-color 0.3s ease;
}

.sankey-save-button:hover {
    background-color: #218838;
}

/* Footer */
.st-modal-footer {
    text-align: right;
    padding: 12px;
    background: #f9f9f9;
    border-top: 1px solid #ddd;
}

.st-modal-footer .st-modal-close-btn {
    background: #dc3545;
    color: white;
    border: none;
    padding: 8px 14px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 0.9rem;
    transition: background-color 0.3s;
}

.st-modal-footer .st-modal-close-btn:hover {
    background: #c82333;
}

/* Visitor Sankey Button */
.visitor-sankey-btn {
    background-color: #6f42c1;
    color: white;
    border: none;
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.visitor-sankey-btn:hover {
    background-color: #5a32a3;
}

/* Additional Adjustments */

/* Center the chart container */
.st-chart-container {
    position: relative;
    width: 100%;
    max-width: 800px; /* Ensures the chart doesn't exceed this width */
    height: 500px;
    margin: 0 auto; /* Centers the chart container */
    overflow: hidden;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 15px;
    background: #fafafa;
}

.st-chart-container canvas {
    width: 100% !important;
    height: 100% !important;
}

/* Ensure table headers have black fonts */
.st-stats-table th,
.st-inner-table th {
    color: black; /* Set all table header fonts to black */
}

/* Responsive Adjustments */
@media (max-width: 768px) {
    .monthly-controls {
        flex-direction: column;
        align-items: stretch;
    }

    .pagination-controls {
        justify-content: space-between;
    }
}
