/* Modal Overlay */
.lodge-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center; /* Center the modal vertically */
    z-index: 1000;
    padding: 20px;
    box-sizing: border-box;
    animation: fadeIn 0.3s ease-in-out;
}

/* Fade-In Animation */
@keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}

/* Modal Content */
.lodge-modal-content {
    background: #ffffff;
    width: 95%;             /* Slightly bigger in smaller screens */
    max-width: 1000px;      /* Increase max-width for a bigger modal */
    border-radius: 12px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.4);
    display: flex;
    flex-direction: column;
    position: relative;
    max-height: 90vh;       /* Control vertical overflow */
    overflow: hidden;       /* Let the scroll container handle overflow */
}

/* Modal Title */
.lodge-modal-title {
    font-size: 1.6em;
    text-align: center;
    margin: 0 0 20px;
    font-weight: bold;
    color: #333333;
    text-transform: uppercase;
    border-bottom: 2px solid #dddddd;
    padding-bottom: 10px;
}

/* Close Button */
.lodge-close-btn {
    position: absolute;
    top: 15px;
    right: 15px;
    background: none;
    border: none;
    font-size: 1.5em;
    color: #333333;
    cursor: pointer;
    transition: color 0.3s ease;
    z-index: 2000; /* Ensure the close button is above everything */
}
.lodge-close-btn:hover {
    color: #555555;
}

/* Scrollable Container */
.lodge-scroll-container {
    overflow-y: auto;
    max-height: 75vh;
    padding: 15px;
    flex: 1;
    scrollbar-width: thin;
    scrollbar-color: #aaaaaa transparent;
}
.lodge-scroll-container::-webkit-scrollbar {
    width: 8px;
}
.lodge-scroll-container::-webkit-scrollbar-thumb {
    background: #aaaaaa;
    border-radius: 4px;
}

/* React-Select Higher Z-Index */
/*div[id^="react-select"] {*/
/*    z-index: 99999 !important; !* Force the dropdown above the modal *!*/
/*}*/

/* Form Groups */
.lodge-form-group {
    margin-bottom: 15px;
}
.lodge-form-group label {
    font-weight: bold;
    margin-bottom: 5px;
    display: block;
}

/* Inputs, Select, Textareas */
.lodge-input,
.lodge-select,
.lodge-textarea,
.lodge-details-modal-date-input,
.lodge-details-modal-comments-input {
    width: 100%;
    padding: 10px;
    margin-top: 5px;
    border: 1px solid #cccccc;
    border-radius: 8px;
    font-size: 1rem;
    transition: border-color 0.3s ease;
    box-sizing: border-box;
}
.lodge-input:focus,
.lodge-select:focus,
.lodge-textarea:focus,
.lodge-details-modal-date-input:focus,
.lodge-details-modal-comments-input:focus {
    border-color: #888888;
    outline: none;
    box-shadow: 0 0 6px rgba(136, 136, 136, 0.4);
}

/* Error States */
.error {
    border-color: red;
    background-color: #ffe6e6;
}
.error-message {
    color: red;
    font-size: 0.9em;
    margin-top: 5px;
}

/* Buttons */
.lodge-save-btn,
.lodge-cancel-btn {
    background: #28a745;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-size: 1rem;
    text-transform: uppercase;
    font-weight: bold;
    margin-right: 10px;
    transition: background-color 0.3s ease;
}
.lodge-save-btn:hover,
.lodge-cancel-btn:hover {
    background: #218838;
}
.lodge-cancel-btn {
    background: #6c757d;
    margin-right: 0;
}
.lodge-cancel-btn:hover {
    background: #5a6268;
}

/* Location History Section */
.lodge-details-modal-location-history-title {
    font-size: 1.3em;
    font-weight: bold;
    margin-bottom: 10px;
    color: #333;
}
.lodge-details-modal-location-history-container {
    margin-top: 20px;
}
.lodge-details-modal-location-list {
    list-style: none;
    padding: 0;
    margin: 0;
}
.lodge-details-modal-location-item {
    background: #f9f9f9;
    border: 1px solid #e6e6e6;
    border-radius: 8px;
    padding: 15px;
    margin-bottom: 12px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
.lodge-details-modal-location-item:hover {
    background: #f1f1f1;
}
.lodge-details-modal-location-details {
    font-size: 0.95em;
    color: #444;
    line-height: 1.6;
}
.lodge-details-modal-location-actions {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
}

/* Location Buttons */
.lodge-details-modal-edit-btn,
.lodge-details-modal-delete-btn {
    background: #007bff;
    color: white;
    border: none;
    padding: 8px 12px;
    border-radius: 6px;
    font-size: 0.9em;
    cursor: pointer;
    transition: background-color 0.3s ease;
}
.lodge-details-modal-edit-btn:hover {
    background: #0056b3;
}
.lodge-details-modal-delete-btn {
    background: #dc3545;
}
.lodge-details-modal-delete-btn:hover {
    background: #c82333;
}

/* Location Form */
.lodge-details-modal-location-form-title {
    font-size: 1.2em;
    font-weight: bold;
    margin-bottom: 15px;
    color: #333;
    text-align: center;
}
.lodge-details-modal-location-form {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 15px;
}
.lodge-details-modal-add-location-btn {
    background-color: #28a745;
    color: #fff;
    padding: 10px 15px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-size: 1rem;
    font-weight: bold;
    text-transform: uppercase;
    transition: background-color 0.3s ease;
    align-self: flex-end;
}
.lodge-details-modal-add-location-btn:hover {
    background-color: #218838;
}

/* Custom class for your top-level lodge select */
.top-level-lodge-select__control {
    border: 2px solid #ccc;
    /* Add or override any styles here */
}

/* Customize the menu */
.top-level-lodge-select__menu {
    /* For instance, a subtle box-shadow: */
    box-shadow: 0 2px 5px rgba(0,0,0,0.2);
}
