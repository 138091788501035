/* MemberStatisticsModal.css */

/* Modal Overlay */
.member-stats-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

/* Modal Content */
.member-stats-modal-content {
    background: #fff;
    padding: 20px;
    width: 90%;
    max-width: 1200px;
    max-height: 90vh;
    overflow-y: auto;
    border-radius: 8px;
    position: relative;
}

/* Modal Header */
.member-stats-modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.member-stats-modal-close-button {
    background: transparent;
    border: none;
    font-size: 24px;
    cursor: pointer;
}

/* Filters Section */
.member-stats-modal-filters {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-bottom: 20px;
}

/* Date Inputs */
.member-stats-modal-date-input {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

/* Statistics Summary */
.member-stats-modal-summary {
    margin-bottom: 20px;
}

.member-stats-modal-summary h3 {
    margin-bottom: 10px;
}

/* Membership Types By Year */
.member-stats-modal-membership-types {
    margin-bottom: 20px;
}

.member-stats-modal-membership-types-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.member-stats-modal-membership-types-controls button {
    margin-left: 10px;
    padding: 5px 10px;
    cursor: pointer;
    background-color: #4CAF50; /* Green */
    border: none;
    color: white;
    border-radius: 4px;
    transition: background-color 0.3s ease;
}

.member-stats-modal-membership-types-controls button:hover {
    background-color: #45a049;
}

.member-stats-modal-membership-types-controls button:last-child {
    background-color: #2196F3; /* Blue */
}

.member-stats-modal-membership-types-controls button:last-child:hover {
    background-color: #0b7dda;
}

/* Chart Container */
.member-stats-modal-chart-container {
    width: 100%;
    height: 400px;
    margin-top: 20px;
}

/* Scrollable Table Container */
.member-stats-modal-scrollable-table-container {
    max-height: 400px;
    overflow-y: auto;
}

/* Table Styles */
.member-stats-modal-scrollable-table {
    width: 100%;
    border-collapse: collapse;
}

.member-stats-modal-scrollable-table th,
.member-stats-modal-scrollable-table td {
    border: 1px solid #ddd;
    padding: 8px;
}

.member-stats-modal-scrollable-table th {
    background-color: #f2f2f2;
    text-align: left;
}

/* Export Button */
.member-stats-modal-export-button {
    margin: 20px 0;
}

.member-stats-modal-export-button button {
    padding: 8px 16px;
    background-color: #555;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.member-stats-modal-export-button button:hover {
    background-color: #333;
}

/* Pagination Controls */
.member-stats-modal-pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0;
}

.member-stats-modal-pagination button {
    padding: 5px 10px;
    margin: 0 10px;
    background-color: #4CAF50;
    border: none;
    color: white;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.member-stats-modal-pagination button:disabled {
    background-color: #a5d6a7;
    cursor: not-allowed;
}

.member-stats-modal-pagination button:hover:not(:disabled) {
    background-color: #45a049;
}

.member-stats-modal-pagination span {
    font-weight: bold;
}

/* Main Statistics Table */
.member-stats-modal-table {
    margin-bottom: 20px;
}

.member-stats-modal-table table {
    width: 100%;
    border-collapse: collapse;
}

.member-stats-modal-table th,
.member-stats-modal-table td {
    border: 1px solid #ddd;
    padding: 8px;
}

.member-stats-modal-table th {
    background-color: #f2f2f2;
    text-align: left;
}

.member-stats-modal-table button {
    margin-top: 10px;
    padding: 5px 10px;
    background-color: #555;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.member-stats-modal-table button:hover {
    background-color: #333;
}

/* Responsive Design */
@media screen and (max-width: 768px) {
    .member-stats-modal-membership-types-header,
    .member-stats-modal-membership-types-controls,
    .member-stats-modal-pagination {
        flex-direction: column;
        align-items: flex-start;
    }

    .member-stats-modal-membership-types-controls button {
        margin: 5px 0;
    }

    .member-stats-modal-pagination button {
        margin: 5px 0;
    }

    .member-stats-modal-filters {
        flex-direction: column;
        align-items: flex-start;
    }

    .member-stats-modal-filters > * {
        width: 100%;
    }
}
