.under-construction {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    /*padding: 40px;*/
    height: 100%; /* Full height for a centered layout */
    background-color: #f9f9f9; /* Light background */
    font-family: 'Merriweather', serif;
    color: #333;
}

.construction-image {
    width: 200px; /* Adjust the size of your image */
    height: auto;
    margin-bottom: 10px;
}

.under-construction h1 {
    font-size: 2rem;
    margin-bottom: 10px;
}

.under-construction p {
    font-size: 1.2rem;
    color: #555;
    margin-bottom: 20px;
}

.back-home-button {
    background-color: #3498db;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.back-home-button:hover {
    background-color: #2980b9;
}
