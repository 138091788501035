/* HistoryOfConnaughtLodge.css */
.history-connaught-container {
    padding: 1rem;
    line-height: 1.6;
}

.history-connaught-container h2 {
    margin-bottom: 1rem;
    color: #333;
}
