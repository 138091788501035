/* Container styles */
.container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
}

h2, h3 {
    text-align: center;
    margin-bottom: 15px;
    font-size: 1.25rem;
}

/*!* Filter form styles *!*/
/*.filter-form2 {*/
/*    display: flex;*/
/*    flex-wrap: wrap;*/
/*    gap: 15px;*/
/*    margin-bottom: 15px;*/
/*}*/

/*.filter-form2 div {*/
/*    flex: 1 1 calc(33% - 15px); !* 3 items per row *!*/
/*    display: flex;*/
/*    flex-direction: column;*/
/*    box-sizing: border-box;*/
/*    min-width: 240px; !* Ensure a minimum width for consistent size *!*/
/*}*/

.filter-form2 {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* 3 equal columns by default */
    gap: 15px; /* Space between items */
    margin-bottom: 15px;
}

.filter-form2 div {
    min-width: 30%; /* Approximately 1/3 width */
    box-sizing: border-box;
}

.filter-form2 input,
.filter-form2 select,
.filter-form2 .react-select__control {
    width: 100%; /* Full width within grid cell */
    box-sizing: border-box; /* Ensure padding and border are included */
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .filter-form2 {
        grid-template-columns: repeat(2, 1fr); /* 2 columns for tablets */
    }
    .filter-form2 div {
        min-width: 45%; /* Slightly less than 50% */
    }
}

@media (max-width: 480px) {
    .filter-form2 {
        grid-template-columns: 1fr; /* Single column for small screens */
    }
    .filter-form2 div {
        min-width: 100%; /* Full width for mobile */
    }
}

.pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin-top: 20px;
}

.pagination button {
    padding: 5px 10px;
    border: none;
    background-color: #007bff;
    color: white;
    border-radius: 4px;
    cursor: pointer;
}

.pagination button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}



/* Table styles */
.document-list {
    margin-top: 15px;
}

.scroll-container {
    /*max-height: 400px;*/
    overflow: auto;
    border: 1px solid #ccc;
    padding: 0px;
}

th, td {

    width: 200px; /* Set all columns to 200px width */
    padding: 8px 12px; /* Adjust padding for consistent spacing */
    border: 1px solid #ddd; /* Add a border for visual clarity */
    text-align: left; /* Align text to the left */
    word-wrap: break-word; /* Ensure long text wraps within the cell */
    overflow-wrap: break-word; /* Handle text overflow gracefully */
}

table {
    table-layout: fixed;
    width: 100%;
    border-collapse: collapse;
    font-size: 15px; /* Ensure a smaller font size for compact rows */
    line-height: 1.2; /* Adjust line height for tighter spacing */
}

th {
    margin: 0;
    background-color: #007bff;
    color: white;
    position: sticky;
    top: 0;
    /*z-index: 2;*/
}

tr:nth-child(even) {
    background-color: #f1f1f1;
}

tr:hover {
    background-color: #e6f7ff;
}

/*th:first-child, td:first-child {*/
/*    width: 200px; !* Make the first column wider *!*/
/*}*/
th:nth-child(1), td:nth-child(1) {
    width: 75px; /* Adjust the width of the second column */
}
th:nth-child(2), td:nth-child(2) {
    width: 75px; /* Adjust the width of the second column */
}

th:nth-child(3), td:nth-child(5) {
    width: 200px; /* Adjust the width of the second column */
}
th:nth-child(4), td:nth-child(6) {
    width: 75px; /* Adjust the width of the second column */
}
th:nth-child(5), td:nth-child(7) {
    width: 300px; /* Adjust the width of the second column */
}
th:nth-child(6), td:nth-child(8) {
    width: 400px; /* Adjust the width of the second column */
}


th:nth-child(17), td:nth-child(3) {
    width: 75px; /* Adjust the width of the second column */
}

th:nth-child(18), td:nth-child(4) {
    width: 75px; /* Adjust the width of the second column */
}
/* Modal styles */
.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content {
    background: #ffffff;
    padding: 20px;
    border-radius: 8px;
    width: 400px;
    max-width: 90%;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    text-align: center;
    font-family: Arial, sans-serif;
}

.modal-content h3 {
    margin-bottom: 15px;
    font-size: 1.25rem;
}

.modal-content ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.modal-content li {
    margin: 10px 0;
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 14px;
}

.modal-content input[type="checkbox"] {
    width: 16px;
    height: 16px;
    cursor: pointer;
}

.modal-content button {
    padding: 8px 15px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    font-weight: bold;
}

.modal-content button:first-child {
    background-color: #28a745;
    color: #fff;
}

.modal-content button:first-child:hover {
    background-color: #218838;
}

.modal-content button:last-child {
    background-color: #dc3545;
    color: #fff;
}

.modal-content button:last-child:hover {
    background-color: #c82333;
}

.modal-content .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 1.25rem;
    color: #999;
    cursor: pointer;
}

.modal-content .close-button:hover {
    color: #333;
}

.preview-button {
    padding: 5px 10px;
    border: none;
    background-color: #17a2b8;
    color: white;
    border-radius: 4px;
    cursor: pointer;
    font-size: 12px;
}

.preview-button:hover {
    background-color: #138496;
}

/*!* Preview button styles *!*/
/*.preview-button {*/
/*    padding: 5px 10px;*/
/*    border: none;*/
/*    background-color: #17a2b8; !* Light blue for distinction *!*/
/*    color: white;*/
/*    border-radius: 4px;*/
/*    cursor: pointer;*/
/*}*/

/*.preview-button:hover {*/
/*    background-color: #138496;*/
/*}*/
/*!* Preview Modal *!*/
/*.preview-modal {*/
/*    position: fixed;*/
/*    top: 0;*/
/*    left: 0;*/
/*    width: 100%;*/
/*    height: 100%;*/
/*    background-color: rgba(0, 0, 0, 0.5);*/
/*    display: flex;*/
/*    justify-content: center;*/
/*    align-items: center;*/
/*    z-index: 1000;*/
/*}*/

/*.preview-modal-content {*/
/*    background: #ffffff;*/
/*    padding: 20px;*/
/*    border-radius: 8px;*/
/*    width: 600px;*/
/*    max-width: 90%;*/
/*    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);*/
/*    text-align: center;*/
/*    font-family: Arial, sans-serif;*/
/*    max-height: 80vh;*/
/*    overflow-y: auto; !* Enable scrolling for large previews *!*/
/*}*/

/*.preview-modal-content h3 {*/
/*    margin-bottom: 15px;*/
/*    font-size: 1.25rem;*/
/*}*/

/*.preview-container {*/
/*    max-height: 400px;*/
/*    overflow-y: auto;*/
/*    border: 1px solid #ddd;*/
/*    padding: 10px;*/
/*    text-align: left;*/
/*}*/

/*.preview-container pre {*/
/*    white-space: pre-wrap;*/
/*    font-size: 14px;*/
/*    line-height: 1.4;*/
/*}*/

/*.preview-container img {*/
/*    max-width: 100%;*/
/*    height: auto;*/
/*    display: block;*/
/*    margin: 0 auto;*/
/*}*/

/*!* Close button *!*/
/*.preview-modal-content .close-button {*/
/*    position: absolute;*/
/*    top: 10px;*/
/*    right: 10px;*/
/*    background: none;*/
/*    border: none;*/
/*    font-size: 1.25rem;*/
/*    color: #999;*/
/*    cursor: pointer;*/
/*}*/

/*.preview-modal-content .close-button:hover {*/
/*    color: #333;*/
/*}*/


