.about-container-unique {
    /*padding: 20px;*/
    max-width: 1200px;
    margin: 0 auto;
    background-color: #f3f4f6;
    color: #333;
    /*font-family: Arial, sans-serif;*/
}


.image-container {
    display: flex;
    justify-content: center; /* Centers horizontally */
    align-items: center; /* Centers vertically */
    height: auto; /* Optional: Define a height if needed */
    text-align: center; /* Ensures alignment of the content */
}
/*.hero-section-unique p,*/
/*.purpose-section-unique p {*/
/*    font-size: 1rem;*/
/*    line-height: 1.6;*/
/*    margin: 10px auto;*/
/*    !*max-width: 800px;*!*/
/*    color: #444;*/
/*    text-aligh: center;*/
/*}*/

.hero-image-unique,
.purpose-image-unique {
    max-width: 80%; /* Ensures it fits the container width */
    height: auto; /* Adjust the height to your preference */
    object-fit: cover; /* Ensures the image maintains its aspect ratio and fills the space */
    margin: 20px 0;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.image-source {
    display: block;
    font-size: 0.9rem;
    color: #666;
    text-align: center;
    margin-top: 5px;
    font-style: italic;
}






/* Card Container */
.card-container-unique {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    margin-top: 20px;
    align-items: stretch;
}

/* Cards */
.card-unique {
    background: #f1f1f1;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 20px;
    flex: 1 1 calc(33% - 30px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transition: transform 0.2s, box-shadow 0.2s;
}

.card-unique:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.15);
}

.card-unique h3 {
    font-size: 1.25rem;
    margin-bottom: 10px;
    font-weight: bold;
    text-align: center;
}

.card-unique p {
    margin-bottom: 15px;
    font-size: 0.95rem;
    text-align: center;
    flex-grow: 1;
}
/*.card-image-unique {*/
/*    border-radius: 8px;*/
/*    box-shadow: 0 4px 6px #0000001a;*/
/*    height: 200px;*/
/*    margin-bottom: 15px;*/
/*    width: 100%; !* Ensures the image fits within the container *!*/
/*    object-fit: contain; !* Displays the entire image while maintaining its aspect ratio *!*/
/*    background-color: #f1f1f1; !* Adds a background color for better appearance with non-rectangular images *!*/
/*    display: block; !* Ensures proper block rendering *!*/
/*}*/

.card-image-unique {
    max-width: 100%;
    height: 200px;
    object-fit: cover;
    border-radius: 8px;
    margin-bottom: 15px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.read-more-button-unique {
    background: #f8f9fa; /* Light gray background */
    color: #333; /* Dark text color */
    border: 1px solid #ccc; /* Light gray border */
    border-radius: 4px;
    padding: 8px 16px; /* Adequate padding for a comfortable click area */
    cursor: pointer;
    font-size: 0.9rem;
    transition: background-color 0.3s, color 0.3s;
    text-align: center;
    display: inline-block;
}

.read-more-button-unique:hover {
    background: #e2e6ea; /* Slightly darker gray on hover */
    color: #000; /* Darker text on hover */
}




/* Modal */
.modal-overlay-unique {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(50, 50, 50, 0.7); /* Darker, more subdued overlay */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

.modal-content-unique {
    background: #fdfdfd; /* Slightly off-white for a warmer feel */
    border: 1px solid #ccc; /* Classic thin border */
    border-radius: 6px;
    width: 75%;
    max-width: 95%;
    max-height: 80vh;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3); /* Softer, more pronounced shadow */
    display: flex;
    flex-direction: column;
    overflow: hidden;
    /*font-family: 'Times New Roman', Times, serif; !* Classic serif font *!*/
}

.modal-header-unique {
    padding: 20px 25px;
    background: #ececec; /* Soft gray background */
    color: #333; /* Darker text for better readability */
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #bbb; /* Subtle bottom border */
    /*font-family: 'Garamond', serif; !* Elegant serif font *!*/
    font-size: 1.2rem;
}

.modal-body-unique {
    padding: 25px;
    overflow-y: auto;
    font-size: 1rem;
    line-height: 1.6;
    text-align: left;
    color: #444; /* Slightly darker text for better readability */
    /*font-family: 'Georgia', serif; !* Classic serif font *!*/
}

.modal-footer-unique {
    padding: 15px 25px;
    background: #ececec; /* Matching header background */
    display: flex;
    justify-content: flex-end;
    border-top: 1px solid #bbb; /* Subtle border */
}

.close-button-unique {
    background: none; /* Transparent background */
    color: #555; /* Neutral dark color */
    border: none;
    font-size: 1.2rem;
    font-weight: bold;
    cursor: pointer;
    transition: color 0.3s;
}

.close-button-unique:hover {
    color: #000; /* Darker on hover */
}

/*.hero-image-placeholder-unique {*/
/*    width: 100%;*/
/*    display: flex;*/
/*    justify-content: center;*/
/*    align-items: center;*/
/*    margin-bottom: 1rem;*/
/*}*/
.xenophon-wrapper {
    position: relative;
    margin-bottom: 1rem;
    padding: 20px; /* optional padding around text */
}

.xenophon-wrapper::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url('images/Adrien_Guignet_Retreat_of_the_ten_thousand.jpg')
    center/cover no-repeat;
    opacity: 0.5; /* Adjust for desired transparency */
    z-index: 1;
}

.xenophon-text-container {
    position: relative;
    z-index: 2;
    background-color: rgba(0, 0, 0, 0.5); /* Slightly translucent background */
    padding: 1rem;
    border-radius: 8px;
    font-style: italic;
    color: white;
}

/* (Optional) styling for your image credit area */
.xenophon-image-credit {
    margin-top: 0.5rem;
    font-size: 0.85rem;
    text-align: right;
    color: white; /* a subtle color */
    z-index: 2; /* ensure it's above the background */
    position: relative; /* so it sits above the pseudo-element as well */
}

/*.image-source {*/
/*    display: block;             !* Ensures the source is on its own line *!*/
/*    !*margin-top: 0rem;        !* Small space above the source text *!*!*/
/*    font-size: 0.8rem;          !* Slightly smaller than regular text *!*/
/*    color: #555;                !* A subtle gray color *!*/
/*    text-align: center;         !* Center-align the source text *!*/
/*    font-style: italic;*/
/*}*/

.image-source {
    display: block;
    margin-top: 0.25rem;
    font-size: 0.8rem;
    color: #555;
    text-align: center;
    font-style: italic;
    min-height: 3.4em; /* Accommodates two lines based on line-height */
    line-height: 1.2em; /* Consistent line height */
    overflow: hidden; /* Hides any additional text beyond two lines */
}

@media (max-width: 768px) {
    .card-container-unique {
        flex-direction: column;
    }

    .card-unique {
        flex: 1 1 100%;
    }

    .xenophon-wrapper::before {
        height: 200px; /* Reduce height for smaller screens */
    }

    .xenophon-text-container {
        padding: 0.5rem;
    }
}

/*.purpose-section-unique {*/
/*    display: flex;*/
/*    justify-content: center;*/
/*    align-items: center;*/
/*    border-radius: 8px;*/
/*    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);*/
/*    margin-bottom: 40px;*/
/*}*/

/*.purpose-content-unique h2 {*/
/*    font-size: 2rem;*/
/*    margin-bottom: 20px;*/
/*}*/

/*.purpose-content-unique p {*/
/*    margin: 0 auto;*/
/*    max-width: 800px;*/
/*    color: #f0f0f0;*/
/*}*/

/*.purpose-section-unique h2 {*/
/*    color: #fff;*/
/*}*/
/* Shared Section Styles */
.shared-section {
    max-width: 1200px;
    margin: 0 auto;
    padding: 40px 20px; /* Consistent padding */
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    text-align: center;
    color: #fff; /* White text for readability */
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

/* Heading Styles */
.shared-section h1, .shared-section h2 {
    font-size: 2.5rem;
    margin-bottom: 20px;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.6); /* Adds text readability */
}

.shared-section p {
    font-size: 1.1rem;
    line-height: 1.8;
    margin: 0 auto;
    max-width: 800px; /* Ensures text width consistency */
    color: #f0f0f0; /* Soft white for better readability */
}

/* Text Container for Background Transparency */
.text-container {
    background-color: rgba(0, 0, 0, 0.6); /* Adds translucent background */
    padding: 20px;
    border-radius: 8px;
}

