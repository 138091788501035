/* Modal Overlay */
.profile-modal-overlay {
    position: fixed;
    inset: 0;
    background-color: rgba(0, 0, 0, 0.7); /* Dark semi-transparent overlay */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    animation: fadeIn 0.3s ease-in-out;
    overflow: hidden; /* Prevent scrolling behind the modal */
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

/* Modal Container */
.profile-modal-content {
    background: #ffffff;
    width: 90%;
    max-width: 900px;
    max-height: 90vh;
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.4);
    overflow: hidden;
    animation: slideDown 0.4s ease-in-out;
    position: relative;
}

@keyframes slideDown {
    from {
        transform: translateY(-30px);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
}

/* Header Section */
.profile-modal-header {
    /*background-color: #333333; !* Dark header for contrast *!*/
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 20px;
    border-bottom: 2px solid #444444;
    position: sticky; /* Remains on top while scrolling */
    top: 0;
    z-index: 1;
}

.profile-modal-header h2 {
    margin: 0;
    font-size: 1.4rem;
    font-weight: bold;
}

.profile-modal-close-btn {
    background: none;
    border: none;
    color: #000000;
    font-size: 1.6rem;
    cursor: pointer;
    transition: transform 0.2s ease, color 0.2s ease;
}

.profile-modal-close-btn:hover {
    transform: scale(1.1);
    color: #ff0a09;
}

/* Body Section (scrollable) */
.profile-modal-body {
    padding: 20px;
    overflow-y: auto; /* Scroll within the modal */
    font-family: "Helvetica Neue", Arial, sans-serif;
    color: #333;
    line-height: 1.6;
    flex: 1; /* So footer stays at bottom */
}

/* Section Headers */
.profile-modal-body h3 {
    margin-top: 24px;
    margin-bottom: 12px;
    font-size: 1.1rem;
    font-weight: bold;
    color: #333333;
    border-bottom: 2px solid #cccccc;
    padding-bottom: 4px;
    text-transform: uppercase;
}

/* Paragraph/Text */
.profile-modal-body p {
    margin: 10px 0;
    font-size: 1rem;
}

.profile-modal-body strong {
    color: #444444;
}

/* Lists (Membership, Roles, Degrees, Meetings) */
.profile-modal-body ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.profile-modal-body li {
    background: #f7f7f7;
    border: 1px solid #dddddd;
    border-radius: 6px;
    padding: 12px 16px;
    margin-bottom: 8px;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
}

.profile-modal-body li:hover {
    background: #f1f1f1;
    transition: background-color 0.2s;
}

/* Footer with Export Button */
.profile-modal-footer {
    background-color: #f9f9f9;
    border-top: 2px solid #eee;
    padding: 12px 20px;
    display: flex;
    justify-content: flex-end;
}

.profile-modal-export-btn {
    background-color: #444444;
    color: #ffffff;
    border: none;
    border-radius: 6px;
    padding: 10px 18px;
    font-size: 0.9rem;
    font-weight: bold;
    cursor: pointer;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    transition: background-color 0.3s ease;
}

.profile-modal-export-btn:hover {
    background-color: #666666;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
    .profile-modal-content {
        width: 95%;
        max-height: 90vh;
    }

    .profile-modal-header h2 {
        font-size: 1.2rem;
    }

    .profile-modal-body {
        padding: 16px;
        font-size: 0.95rem;
    }

    .profile-modal-export-btn {
        width: 100%;
        margin-top: 8px;
        font-size: 1rem;
    }
}
