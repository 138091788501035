.building-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6); /* Slightly darker overlay */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
    overflow-y: auto;
}

.building-modal-content {
    background: #fff;
    padding: 25px;
    border-radius: 8px;
    width: 90%;
    max-width: 800px;
    position: relative;
    overflow-y: auto;
    max-height: 90%;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2); /* Subtle shadow for depth */
}

.building-close-btn {
    position: absolute;
    top: 10px;
    right: 15px;
    background: none;
    border: none;
    font-size: 24px;
    color: #333;
    cursor: pointer;
    transition: color 0.3s ease;
}

.building-close-btn:hover {
    color: #007bff;
}

.building-form-group {
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
}

.building-form-group label {
    font-weight: bold;
    margin-bottom: 8px;
    color: #333;
}

.building-form-group input,
.building-form-group select,
.building-form-group textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
    transition: border-color 0.3s ease;
}

.building-form-group input:focus,
.building-form-group select:focus,
.building-form-group textarea:focus {
    border-color: #007bff;
    outline: none;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.3);
}

.building-form-group input.error,
.building-form-group select.error,
.building-form-group textarea.error {
    border-color: #dc3545;
    background-color: #f8d7da;
}

.building-form-group .error-message {
    color: #dc3545;
    font-size: 12px;
    margin-top: 5px;
}

.building-modal-actions {
    display: flex;
    justify-content: flex-end;
    gap: 15px;
    margin-top: 20px;
}

.building-save-btn {
    background-color: #28a745;
    color: white;
    padding: 12px 18px;
    border: none;
    border-radius: 5px;
    font-size: 14px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.building-save-btn:hover {
    background-color: #218838;
}

.building-delete-btn {
    background-color: #dc3545;
    color: white;
    padding: 12px 18px;
    border: none;
    border-radius: 5px;
    font-size: 14px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.building-delete-btn:hover {
    background-color: #c82333;
}

.building-cancel-btn {
    background-color: #6c757d;
    color: white;
    padding: 12px 18px;
    border: none;
    border-radius: 5px;
    font-size: 14px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.building-cancel-btn:hover {
    background-color: #5a6268;
}

.error-highlight {
    background-color: #f8d7da;
    border: 1px solid #dc3545;
    color: #721c24;
}

.error-highlight input::placeholder {
    color: #dc3545;
    opacity: 0.8;
}

.building-modal-content input.error {
    border: 1px solid red;
}

.error-message {
    color: red;
    font-size: 0.875rem;
    margin-top: 5px;
}

/* Button to Show Map */
.building-map-btn {
    display: inline-block;
    background-color: #007bff; /* Primary blue */
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 10px 15px;
    font-size: 1rem;
    font-weight: bold;
    text-transform: uppercase;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    text-align: center;
    margin: 10px 0; /* Add spacing */
}

.building-map-btn:hover {
    background-color: #0056b3; /* Darker blue on hover */
    transform: scale(1.05); /* Slight zoom effect */
}

.building-map-btn:active {
    background-color: #004085; /* Even darker on click */
    transform: scale(0.95);
}

@media (max-width: 768px) {
    .building-map-btn {
        width: 100%; /* Full width on small screens */
        font-size: 0.9rem;
    }
}

/* Map Modal Overlay */
.map-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8); /* Dark overlay */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10000; /* Ensure it’s higher than building modal */
}

/* Map Modal Content */
.map-modal-content {
    background: white;
    border-radius: 8px;
    padding: 20px;
    width: 90%;
    max-width: 1200px;
    height: 85%;
    position: relative;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
    overflow: hidden;
}

/* Close Button */
.map-close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    background: #f44336;
    color: white;
    border: none;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    font-size: 1.2rem;
    cursor: pointer;
}

.map-close-btn:hover {
    background: #d32f2f;
}


html {
    scroll-behavior: smooth; /* Smooth scrolling for the entire page */
}

.map-modal-content {
    overflow-y: auto;
    transition: all 0.3s ease; /* Smooth animations for opening/closing */
}

.image-preview-container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    /*width: 80%;*/
}

.image-preview-container img {
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 5px;
    background-color: #f9f9f9;
}
