/* Container styles */
.container3 {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
}

h2, h3 {
    text-align: center;
    margin-bottom: 15px;
    font-size: 1.25rem;
    color: #333;
}

/* Form sections */
.form-section3 {
    margin-bottom: 30px;
    padding: 20px;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05);
}

.form-section3 h3 {
    margin-bottom: 15px;
    border-bottom: 1px solid #ddd;
    padding-bottom: 5px;
    font-size: 1rem;
    color: #555;
}

/*!* Grid layout for form fields *!*/
/*.filter-form2 {*/
/*    display: grid;*/
/*    grid-template-columns: repeat(3, 1fr); !* 3 columns by default *!*/
/*    gap: 15px;*/
/*    margin-bottom: 15px;*/
/*}*/

/*.filter-form2 div {*/
/*    display: flex;*/
/*    flex-direction: column;*/
/*    min-width: 240px;*/
/*}*/

label {
    font-weight: bold;
    margin-bottom: 5px;
    font-size: 14px;
    color: #555;
}

/* Styling for inputs and react-select */
/*input[type="text"],*/
/*input[type="date"],*/
/*textarea,*/
/*.react-select__control {*/
/*    padding: 8px;*/
/*    border: 1px solid #ccc;*/
/*    border-radius: 4px;*/
/*    width: 100%;*/
/*    box-sizing: border-box;*/
/*    font-size: 14px;*/
/*}*/

input[type="text"],
input[type="date"],
textarea {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 100%;
    box-sizing: border-box;
    font-size: 14px;
}

textarea {
    resize: vertical;
    min-height: 80px;
}

/*.react-select__control {*/
/*    min-height: 40px;*/
/*    font-size: 14px;*/
/*    border-radius: 4px;*/
/*}*/

/*.react-select__menu {*/
/*    z-index: 10;*/
/*    background-color: #ffffff;*/
/*    border: 1px solid #ccc;*/
/*    border-radius: 4px;*/
/*}*/

/*.react-select__multi-value {*/
/*    background-color: #e6f7ff;*/
/*    border: 1px solid #007bff;*/
/*    border-radius: 4px;*/
/*    padding: 2px 5px;*/
/*    font-size: 12px;*/
/*}*/

/*.react-select__multi-value__label {*/
/*    color: #007bff;*/
/*}*/

/*.react-select__multi-value__remove {*/
/*    color: #007bff;*/
/*    cursor: pointer;*/
/*}*/

/*.react-select__multi-value__remove:hover {*/
/*    color: #0056b3;*/
/*}*/

/* Error messages */
.error3 {
    color: red;
    font-size: 12px;
    margin-top: 5px;
}

/* Submit button styles */
button[type="submit"] {
    background-color: #007bff;
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    width: 100%;
    box-sizing: border-box;
}

button[type="submit"]:hover {
    background-color: #0056b3;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .filter-form2 {
        grid-template-columns: repeat(2, 1fr); /* 2 columns for tablets */
    }

    .filter-form2 div {
        min-width: 45%;
    }
}

@media (max-width: 480px) {
    /*.filter-form2 {*/
    /*    grid-template-columns: 1fr; !* Single column for small screens *!*/
    /*}*/

    /*.filter-form2 div {*/
    /*    min-width: 100%;*/
    /*}*/
}

/* File Upload Section */
.form-section3 input[type="file"] {
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
    width: 100%;
}

/* Scrollable table container */
.scroll-container {
    /*max-height: 400px;*/
    overflow: auto;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-top: 20px;
}

/*table {*/
/*    width: 100%;*/
/*    border-collapse: collapse;*/
/*    font-size: 12px;*/
/*}*/

/*th, td {*/
/*    padding: 8px;*/
/*    border: 1px solid #ddd;*/
/*    text-align: left;*/
/*}*/

/*th {*/
/*    background-color: #007bff;*/
/*    color: white;*/
/*    position: sticky;*/
/*    top: 0;*/
/*    z-index: 2;*/
/*}*/

/*tr:nth-child(even) {*/
/*    background-color: #f1f1f1;*/
/*}*/

/*tr:hover {*/
/*    background-color: #e6f7ff;*/
/*}*/

.progress-container {
    margin: 20px 0;
    font-size: 1rem;
    color: #333;
}

.progress-bar {
    width: 100%;
    height: 10px;
    background-color: #ddd;
    border-radius: 5px;
    overflow: hidden;
    margin-top: 5px;
    margin-bottom: 5px;
}

.progress-bar-fill {
    height: 100%;
    background-color: #4caf50; /* green progress color */
    transition: width 0.4s ease;
}
