.disclaimer-banner {
    background-color: #5D92D2; /* Soft blue for subtle attention */
    color: #FFFFFF; /* White text for contrast */
    text-align: center;
    /*padding: 12px; !* Slightly more padding for a spacious look *!*/
    font-size: 1.1em; /* Slightly larger font for readability */
    font-weight: 600; /* Medium boldness */
    border-bottom: 2px solid #4A78A6; /* Slightly darker blue for the border */
    /*box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); !* Soft shadow for depth *!*/
    z-index: 500; /* Make sure it stays on top */
    position: sticky; /* Sticks to the top */
    top: 0; /* Stick to the top */
    width: 100%; /* Full width */
}

/* Responsive adjustments for smaller screens */
@media (max-width: 768px) {
    .disclaimer-banner {
        /*padding: 10px; !* Reduce padding on smaller screens *!*/
        font-size: 1em; /* Slightly smaller font for mobile */
    }
}
