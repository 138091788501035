/* General Modal Styling */
.md-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 500;
    font-size: 0.9rem;
}

.md-modal-content {
    background: #fff;
    width: 80%;
    max-width: 800px;
    max-height: 90%;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.4);
    z-index: 2000;
}

/* Modal Header */
.md-modal-header {
    background: #f8f9fa;
    padding: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ddd;
}

.md-modal-header h2 {
    margin: 0;
    font-size: 1.5rem;
    font-weight: bold;
}

.md-modal-close-btn {
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
    color: #333;
    padding: 5px;
    transition: color 0.3s ease;
}

.md-modal-close-btn:hover {
    color: #dc3545;
}

/* Modal Body */
.md-modal-body {
    padding: 20px;
    overflow-y: auto;
    flex: 1;
}

/* Meeting List */
.md-meeting-list {
    list-style: none;
    padding: 0;
    margin: 0;
}

.md-meeting-item {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 12px 15px;
    border: 1px solid #ddd;
    border-radius: 5px;
    margin-bottom: 12px;
    background: #f9f9f9;
}

.md-meeting-item:hover {
    background: #f1f1f1;
}

.md-meeting-info {
    flex: 1;
    margin-right: 15px;
}

.md-meeting-buttons {
    display: flex;
    flex-direction: column; /* Ensure buttons stack */
    gap: 8px; /* Uniform spacing between buttons */
}

.md-meeting-edit-btn,
.md-meeting-delete-btn,
.md-meeting-attendees-btn {
    background: #007bff;
    border: none;
    color: white;
    padding: 8px 12px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 0.95rem;
    transition: background-color 0.3s ease;
    text-align: center;
}

.md-meeting-delete-btn {
    background: #dc3545;
}

.md-meeting-attendees-btn {
    background: #6f42c1;
}

.md-meeting-edit-btn:hover {
    background: #0056b3;
}

.md-meeting-delete-btn:hover {
    background: #c82333;
}

.md-meeting-attendees-btn:hover {
    background: #5a2b91;
}

/* Form */
.md-meeting-form {
    margin-top: 16px;
}

.md-meeting-form label {
    font-weight: bold;
    margin-bottom: 5px;
    display: block;
}

.md-meeting-form input,
.md-meeting-form select,
.md-meeting-form textarea {
    width: 100%;
    margin-bottom: 12px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 1rem;
}

.md-meeting-save-btn {
    background: #28a745;
    color: white;
    border: none;
    padding: 12px;
    border-radius: 5px;
    cursor: pointer;
    width: 100%;
    font-size: 1rem;
    margin-top: 12px;
    font-weight: bold;
    transition: background-color 0.3s ease;
}

.md-meeting-save-btn:hover {
    background: #218838;
}
