.stats-buildings-age-overlay {
    position: fixed;
    z-index: 9999;
    top: 0; left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.stats-buildings-age-content {
    background: #fff;
    width: 90%;
    max-width: 900px;
    max-height: 90vh;
    overflow-y: auto;
    border-radius: 6px;
    position: relative;
    padding: 20px;
}

.stats-buildings-age-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
}
.stats-buildings-age-header h2 {
    margin: 0;
}
.close-button {
    background: none;
    border: none;
    font-size: 1.25rem;
    cursor: pointer;
}

.stats-buildings-age-body {
    padding: 1rem 0;
}

.loading-spinner {
    text-align: center;
    margin: 2rem 0;
}
.error-message {
    color: red;
    text-align: center;
    margin: 2rem 0;
}

.filters-row {
    margin-bottom: 1rem;
    display: flex;
    gap: 1rem;
    align-items: center;
}
.filters-row label {
    font-weight: 500;
    display: flex;
    align-items: center;
    gap: 0.5rem;
}
.export-png-button {
    background: #007bff;
    border: none;
    color: #fff;
    padding: 0.4rem 0.8rem;
    border-radius: 4px;
    cursor: pointer;
}
.export-png-button:hover {
    background: #0056b3;
}

.chart-wrapper {
    width: 100%;
    height: 400px;
    position: relative;
    margin-bottom: 2rem;
}

.data-table-section {
    margin-top: 1rem;
}
.data-table-section h3 {
    margin-bottom: 0.5rem;
}

.age-status-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 1rem;
}
.age-status-table th, .age-status-table td {
    border: 1px solid #ddd;
    padding: 0.6rem;
    text-align: center;
}
.age-status-table th {
    background: #f2f2f2;
    color: black;
}
.age-status-table tr:nth-child(even) {
    background-color: #fafafa;
}

.stats-buildings-age-footer {
    text-align: right;
    margin-top: 1rem;
}
