.highlights-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7); /* Dark overlay */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.highlights-modal-content {
    background: white;
    padding: 30px;
    border-radius: 12px;
    width: 75%;
    max-height: 90%;
    overflow-y: auto;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
    position: relative; /* Needed to position the close button inside */
    animation: fadeIn 0.3s ease-in-out;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: scale(0.95);
    }
    to {
        opacity: 1;
        transform: scale(1);
    }
}

.highlights-form input,
.highlights-form textarea {
    width: 100%;
    margin-bottom: 15px;
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 6px;
    font-size: 16px;
    background: #f8f8f8;
    transition: border 0.2s ease-in-out;
}

.highlights-form input:focus,
.highlights-form textarea:focus {
    border-color: #007bff;
    outline: none;
}

.highlights-form button {
    width: 100%;
    padding: 12px;
    background: #007bff;
    color: white;
    font-size: 16px;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    transition: background 0.3s ease;
}

.highlights-form button:hover {
    background: #0056b3;
}

.highlights-list {
    margin-top: 20px;
    font-family: Arial, sans-serif;
}

.highlight-item {
    background: #f4f4f4;
    padding: 15px;
    border: 1px solid #e0e0e0;
    margin-bottom: 15px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.highlight-item h3 {
    margin: 0 0 8px;
    font-size: 18px;
    color: #333;
}

.highlight-item p {
    font-size: 14px;
    color: #555;
    line-height: 1.5;
}

.highlight-item small {
    font-size: 12px;
    color: #777;
}

.highlight-actions {
    margin-top: 10px;
    display: flex;
    gap: 10px;
}

.highlight-actions button {
    padding: 8px 12px;
    font-size: 14px;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    transition: background 0.3s ease;
}

.highlight-actions button:first-of-type {
    background: #28a745;
    color: white;
}

.highlight-actions button:first-of-type:hover {
    background: #218838;
}

.highlight-actions button:last-of-type {
    background: #dc3545;
    color: white;
}

.highlight-actions button:last-of-type:hover {
    background: #c82333;
}

/* Close button styling inside modal */
.close-btn {
    background: transparent;
    border: none;
    font-size: 24px;
    color: #333;
    position: absolute;
    top: 15px;
    right: 15px; /* Positioned inside the modal */
    cursor: pointer;
    transition: color 0.3s ease;
}

.close-btn:hover {
    color: #007bff;
}
