/* Footer Styles */
footer {
    background-color: #4267B2; /* Muted Blue Color */
    color: #FFFFFF; /* White Text */
    text-align: center;
    padding: 20px;
    font-family: 'Georgia', serif;
    font-size: 0.9em;
    box-shadow: 0 -4px 8px rgba(0, 0, 0, 0.1);
    position: relative;
    bottom: 0;
    width: 100%;
}

footer p {
    margin: 10px 0;
    line-height: 1.5;
}

/* Emphasized Text */
footer p:first-of-type {
    font-weight: bold;
}

/* Links in Footer */
footer p a {
    color: #dcdcdc; /* Light Gray for Links */
    text-decoration: underline;
}

footer p a:hover {
    text-decoration: none;
    color: #f0f0f0; /* Slightly darker gray on hover */
}

/* Responsive Footer for Small Screens */
@media (max-width: 768px) {
    footer {
        padding: 15px;
        font-size: 0.8em;
    }

    footer p {
        margin: 5px 0;
    }
}
