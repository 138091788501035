/* Overlay */
.history-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

/* Modal Content */
.history-modal-content {
    background: #f9f9f9;
    padding: 20px;
    border-radius: 8px;
    max-width: 800px;
    width: 90%;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    animation: fadeIn 0.3s ease-out;
    display: flex;
    flex-direction: column;
    max-height: 90vh;
}

/* Header */
.history-modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
    border-bottom: 1px solid #ddd;
    padding-bottom: 10px;
}

.history-modal-close-button {
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
    color: #555;
    transition: color 0.2s;
}

.history-modal-close-button:hover {
    color: #333;
}

/* Body */
.history-modal-body {
    overflow-y: auto;
    flex-grow: 1;
}

/* Image Gallery */
.image-gallery {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin: 20px 0;
    justify-content: center;
}

.modal-image {
    max-width: 100%;
    max-height: 200px;
    object-fit: cover;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Image Source */
.image-source {
    text-align: center;
    font-size: 0.9rem;
    color: #555;
}

/* Fade-in Animation */
@keyframes fadeIn {
    from {
        opacity: 0;
        transform: scale(0.95);
    }
    to {
        opacity: 1;
        transform: scale(1);
    }
}

.open-modal-button {
    background-color: #f1f1f1;
    color: #333;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.open-modal-button:hover {
    background-color: #e0e0e0;
    color: #000;
    border-color: #aaa;
}

.open-modal-button:active {
    background-color: #d6d6d6;
    box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.2);
}
