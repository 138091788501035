/* AdminPanel.css */

.admin-panel {
    padding: 20px;
    font-family: 'Arial', sans-serif;
    background-color: #f0f2f5;
}

h1 {
    text-align: center;
    margin-bottom: 20px;
    font-size: 24px;
    color: #333;
}

.dropdown-container {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

.table-dropdown {
    padding: 8px;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.table-container {
    margin-top: 20px;
}

.table-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.table-header h2 {
    margin: 0;
    font-size: 20px;
    color: #333;
}

.add-button {
    background-color: #007bff;
    color: white;
    padding: 6px 12px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.add-button:hover {
    background-color: #0056b3;
}

.data-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px;
    background-color: white;
}

.data-table th,
.data-table td {
    padding: 10px;
    border: 1px solid #ddd;
    font-size: 14px;
    color: #333;
}

.data-table th {
    background-color: #f8f9fa;
    text-align: left;
}

.data-table tbody tr:nth-child(even) {
    background-color: #fdfdfe;
}

.edit-btn,
.delete-btn {
    margin: 0 5px;
    cursor: pointer;
    background: none;
    border: none;
    padding: 4px 8px;
    font-size: 14px;
}

.edit-btn {
    color: #17a2b8;
}

.edit-btn:hover {
    color: #117a8b;
}

.delete-btn {
    color: #dc3545;
}

.delete-btn:hover {
    color: #bd2130;
}

.delete-btn:disabled {
    color: #6c757d;
    cursor: not-allowed;
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content {
    background: #fff;
    padding: 20px;
    border-radius: 6px;
    width: 90%;
    max-width: 400px;
    position: relative;
}

.modal-content h3 {
    margin-top: 0;
    margin-bottom: 15px;
    font-size: 18px;
    color: #333;
}

.form-group {
    margin-bottom: 10px;
    text-align: left;
}

.form-group label {
    display: block;
    margin-bottom: 3px;
    font-weight: 500;
    font-size: 14px;
    color: #333;
}

.form-group input {
    width: 100%;
    padding: 6px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
}

.modal-actions {
    display: flex;
    justify-content: flex-end;
    margin-top: 15px;
}

.modal-actions .btn {
    margin-left: 10px;
    padding: 6px 12px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
}

.btn-save {
    background-color: #28a745;
    color: white;
}

.btn-save:hover {
    background-color: #218838;
}

.btn-cancel {
    background-color: #6c757d;
    color: white;
}

.btn-cancel:hover {
    background-color: #5a6268;
}

.btn-confirm-yes {
    background-color: #28a745;
    color: white;
}

.btn-confirm-yes:hover {
    background-color: #218838;
}

.btn-confirm-no {
    background-color: #dc3545;
    color: white;
}

.btn-confirm-no:hover {
    background-color: #c82333;
}

@media (max-width: 600px) {
    .table-header h2 {
        font-size: 18px;
    }

    .add-button {
        padding: 5px 10px;
        font-size: 12px;
    }

    .data-table th,
    .data-table td {
        padding: 8px;
        font-size: 12px;
    }

    .modal-content {
        padding: 15px;
    }

    .modal-content h3 {
        font-size: 16px;
    }

    .form-group label,
    .form-group input {
        font-size: 12px;
    }

    .modal-actions .btn {
        padding: 5px 10px;
        font-size: 12px;
    }
}
