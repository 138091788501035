/* Modal Overlay */
.statistics-modal-overlay-unique {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

/* Modal Content */
.statistics-modal-content-unique {
    background: #ffffff;
    border-radius: 10px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
    padding: 20px;
    width: 90%;
    max-width: 800px;
    height: 50%;
    overflow: hidden;
    /*font-family: Arial, sans-serif;*/
    position: relative;
}

/* Modal Header */
.statistics-modal-header-unique {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    border-bottom: 1px solid #ddd;
    padding-bottom: 10px;
}

.statistics-modal-header-unique h2 {
    font-family: "Times New Roman", Times, serif;
    font-size: 1rem;
    font-weight: bold;
    color: #333;
    margin-top: 10px;
    text-align: center;
}

.statistics-modal-close-button-unique {
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
    color: #333;
    transition: color 0.2s ease;
}

.statistics-modal-close-button-unique:hover {
    color: #666;
}

/* Scrollable Body */
.statistics-modal-body-unique {
    overflow-y: auto;
    overflow-x: hidden;
    height: calc(100% - 60px); /* Adjust height to fit header/footer */
    padding: 10px;
}

/* Grid Layout for Statistics Sections */
.statistics-grid-unique {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    margin: 0 auto;
}

/* Individual Stat Cards */
.statistics-card-unique {
    background: rgb(241, 241, 241);
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 10px;
    text-align: center;
    cursor: pointer;
    transition: transform 0.2s, box-shadow 0.2s;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 140px;
    height: 140px;
    position: relative;
    z-index: 2; /* Ensure it is above the grid but below tooltips */
}

.statistics-card-unique:hover {
    transform: scale(1.05);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

/* Card Icon */
.statistics-card-icon-unique {
    width: 80px;
    height: auto;
    margin-bottom: 10px;
}

/* Card Title */
.statistics-card-title-unique {
    font-family: "Times New Roman", Times, serif;
    font-size: 1rem;
    font-weight: bold;
    color: #333;
    margin-top: 10px;
    text-align: center;
}

/* Card Description */
.statistics-card-description-unique {
    font-size: 0.9rem;
    color: #666;
    margin: 0;
    text-align: center;
}

/* Disabled Cards */
.statistics-card-disabled-unique {
    opacity: 0.5;
    cursor: not-allowed;
}

/* Modal Footer */
.statistics-modal-footer-unique {
    margin-top: 20px;
    text-align: center;
}

/* Media Query for Mobile */
@media (max-width: 768px) {
    .statistics-modal-content-unique {
        width: 95%;
        height: 90%;
        padding: 15px;
    }

    .statistics-modal-header-unique h2 {
        font-size: 1.0rem;
    }

    .statistics-card-unique {
        width: 140px;
        height: 140px;
    }

    .statistics-card-title-unique {
        font-size: 1rem;
    }

    .statistics-card-icon-unique {
        width: 60px;
    }
}
