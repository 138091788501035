.resources-page-container {
    padding: 2rem;
    text-align: center;
    /*font-family: Arial, sans-serif;*/
}

.resources-header {
    margin-bottom: 2rem;
}

.resources-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 15px;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    position: relative; /* Establish stacking context for the grid */
    z-index: 1; /* Ensure tooltips are above the grid */
}

.resources-card {
    background: rgb(241, 241, 241);
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 10px;
    text-align: center;
    cursor: pointer;
    transition: transform 0.2s, box-shadow 0.2s;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 140px;
    height: 140px;
    position: relative;
    z-index: 2; /* Ensure it is above the grid but below tooltips */
}

.resources-card-icon {
    width: 80px;
    /*height: 80px;*/
    flex-shrink: 0;
}

.resources-card-title {
    font-family: "Times New Roman", Times, serif;
    font-size: 1rem;
    font-weight: bold;
    color: #333;
    margin-top: 10px;
    text-align: center;
}

.resources-card:hover {
    transform: scale(1.05);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.resources-card-disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.resources-tooltip {
    position: absolute;
    background-color: #333;
    color: #fff;
    padding: 0.5rem;
    border-radius: 4px;
    font-size: 0.9rem;
    transform: translateX(-50%);
    white-space: nowrap;
    z-index: 10;
}
