.member-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    animation: fadeIn 0.2s ease-in-out;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.member-modal-content {
    background: #f8f9fa;
    padding: 15px;
    border-radius: 8px;
    width: 90%;
    max-width: 700px;
    position: relative;
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.2);
    overflow-y: auto;
    max-height: 85vh;
    animation: slideIn 0.2s ease-in-out;
}

@keyframes slideIn {
    from {
        transform: translateY(-30px);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
}

.member-close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 1.2rem;
    cursor: pointer;
    color: #555;
    transition: color 0.2s ease-in-out;
}

.member-close-btn:hover {
    color: #d9534f;
}

.member-form-container {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.member-form-group {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.member-form-group label {
    font-weight: 600;
    font-size: 0.9rem;
    color: #495057;
    margin-bottom: 2px;
}

.member-form-group input,
.member-form-group select {
    width: 100%;
    padding: 8px;
    border: 1px solid #ced4da;
    border-radius: 4px;
    font-size: 0.9rem;
    background-color: #ffffff;
    transition: border-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
}

.member-form-group input:focus,
.member-form-group select:focus {
    border-color: #80bdff;
    box-shadow: 0 0 3px rgba(0, 123, 255, 0.2);
    outline: none;
}

.related-data-buttons {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 15px;
    justify-content: space-between;
}

.related-data-buttons button {
    flex: 1;
    padding: 8px 10px;
    font-size: 0.85rem;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out, transform 0.1s ease;
    text-align: center;
}

.related-data-buttons button:hover {
    background-color: #0056b3;
    transform: translateY(-1px);
}

.related-data-buttons button.disabled {
    background-color: #e9ecef;
    color: #6c757d;
    cursor: not-allowed;
    pointer-events: none;
}

.member-modal-actions {
    margin-top: 15px;
    display: flex;
    justify-content: space-between;
}

.member-save-btn,
.member-cancel-btn {
    flex: 1;
    padding: 8px 12px;
    border: none;
    border-radius: 4px;
    font-size: 0.9rem;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out, transform 0.1s ease;
}

.member-save-btn {
    background-color: #28a745;
    color: white;
    margin-right: 5px;
}

.member-save-btn:hover {
    background-color: #218838;
    transform: translateY(-1px);
}

.member-cancel-btn {
    background-color: #dc3545;
    color: white;
    margin-left: 5px;
}

.member-cancel-btn:hover {
    background-color: #c82333;
    transform: translateY(-1px);
}

.error-message {
    color: #d9534f;
    font-size: 0.8rem;
    margin-top: 3px;
}

.error {
    border-color: #d9534f;
    box-shadow: 0 0 3px rgba(217, 83, 79, 0.2);
}

.md-meeting-list {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.md-meeting-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #ffffff;
    padding: 8px 12px;
    border: 1px solid #dee2e6;
    border-radius: 4px;
    font-size: 0.85rem;
    color: #495057;
    transition: box-shadow 0.2s ease-in-out;
}

.md-meeting-item:hover {
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
}

.md-meeting-buttons {
    display: flex;
    gap: 8px;
}

.md-meeting-buttons button {
    padding: 5px 10px;
    font-size: 0.8rem;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
}

.md-meeting-buttons .md-meeting-edit-btn {
    background-color: #ffc107;
    color: #212529;
}

.md-meeting-buttons .md-meeting-edit-btn:hover {
    background-color: #e0a800;
}

.md-meeting-buttons .md-meeting-delete-btn {
    background-color: #dc3545;
    color: white;
}

.md-meeting-buttons .md-meeting-delete-btn:hover {
    background-color: #c82333;
}

.md-meeting-buttons .md-meeting-attendees-btn {
    background-color: #007bff;
    color: white;
}

.md-meeting-buttons .md-meeting-attendees-btn:hover {
    background-color: #0056b3;
}
