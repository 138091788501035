/* Modal Overlay */
.meeting-attendees-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2000;
}

/* Modal Content */
.meeting-attendees-modal-content {
    background: #fff;
    padding: 20px;
    width: 90%;
    max-width: 800px;
    max-height: 90vh;
    overflow-y: auto;
    overflow-x: hidden;
    border-radius: 12px;
    position: relative;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.3);
    z-index: 2100;
}

/* Modal Header */
.meeting-attendees-modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    padding-bottom: 10px;
    border-bottom: 1px solid #ddd;
}

.meeting-attendees-modal-header h2 {
    font-size: 1.5rem;
    font-weight: bold;
}

.meeting-attendees-modal-close-button {
    background: transparent;
    border: none;
    font-size: 24px;
    cursor: pointer;
    color: #333;
    transition: color 0.3s ease;
}

.meeting-attendees-modal-close-button:hover {
    color: #dc3545;
}

/* Modal Body */
.meeting-attendees-modal-body {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

/* Attendees List */
.meeting-attendees-modal-attendee-list {
    list-style: none;
    padding: 0;
    margin: 0;
}

.meeting-attendees-modal-attendee-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 15px;
    border: 1px solid #ddd;
    border-radius: 6px;
    background-color: #f9f9f9;
    margin-bottom: 10px;
    font-size: 1rem;
}

.meeting-attendees-modal-attendee-item:hover {
    background-color: #f1f1f1;
}

.meeting-attendees-modal-attendee-delete-button {
    background-color: #ff4d4f;
    border: none;
    color: white;
    padding: 6px 12px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 0.9rem;
    transition: background-color 0.3s ease;
}

.meeting-attendees-modal-attendee-delete-button:hover {
    background-color: #ff7875;
}

/* Add Attendee Form */
.meeting-attendees-modal-form {
    background: #f8f9fa;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.meeting-attendees-modal-form h3 {
    margin-bottom: 15px;
    font-size: 1.25rem;
    font-weight: bold;
}

.meeting-attendees-modal-form-group {
    margin-bottom: 15px;
}

.meeting-attendees-modal-form-group label {
    font-weight: bold;
    margin-bottom: 5px;
    display: block;
}

.meeting-attendees-modal-form-group-checkbox {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}

.meeting-attendees-modal-form-group-checkbox input {
    margin-right: 8px;
}

.meeting-attendees-modal-save-button {
    background-color: #28a745;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 6px;
    font-size: 1rem;
    font-weight: bold;
    cursor: pointer;
    width: 100%;
    text-align: center;
    transition: background-color 0.3s ease;
}

.meeting-attendees-modal-save-button:hover {
    background-color: #218838;
}

.meeting-attendees-modal-save-button:disabled {
    background-color: #a5d6a7;
    cursor: not-allowed;
}

/* Error Messages */
.meeting-attendees-modal-error {
    color: red;
    font-size: 0.85rem;
    margin-top: 5px;
}

/* Footer */
.meeting-attendees-modal-footer {
    margin-top: 20px;
    text-align: center;
}

.meeting-attendees-modal-close-footer-button {
    background-color: #555;
    color: white;
    border: none;
    padding: 8px 16px;
    border-radius: 6px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.meeting-attendees-modal-close-footer-button:hover {
    background-color: #333;
}

/* Responsive Design */
@media screen and (max-width: 600px) {
    .meeting-attendees-modal-content {
        width: 95%;
        padding: 15px;
    }

    .meeting-attendees-modal-attendee-item {
        flex-direction: column;
        align-items: flex-start;
    }

    .meeting-attendees-modal-attendee-delete-button {
        margin-top: 10px;
    }
}
