/* Modal Overlay */
.membership-history-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    overflow: auto;
    animation: fadeIn 0.3s ease-in-out;
    overflow: hidden; /* Disable scrolling for the overlay */
}



@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

/* Modal Content */
.membership-history-modal-content {
    background: #ffffff;
    width: 80%; /* Increased width */
    max-width: 900px; /* Increased max-width */
    max-height: 90vh;
    border-radius: 12px;
    overflow: hidden;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.4);
    display: flex;
    flex-direction: column;
    position: relative; /* Ensure the close button is positioned relative to the modal */
    padding: 20px; /* Added padding for inner spacing */
}

/* Scroll Container */
.membership-history-scroll-container {
    overflow-y: auto;
    padding: 20px; /* Adjusted for consistent spacing */
    flex: 1;
    scrollbar-width: thin;
    scrollbar-color: #aaaaaa transparent;
}

.membership-history-scroll-container::-webkit-scrollbar {
    width: 8px;
}

.membership-history-scroll-container::-webkit-scrollbar-thumb {
    background: #aaaaaa;
    border-radius: 4px;
}

/* Modal Title */
.membership-history-modal-title {
    font-size: 1.6em; /* Increased title size */
    text-align: center;
    margin: 20px 0;
    font-weight: bold;
    color: #333333;
    text-transform: uppercase;
    border-bottom: 2px solid #dddddd;
    padding-bottom: 10px;
}

/* List Items */
.membership-history-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.membership-history-item {
    background: #f7f7f7;
    padding: 15px;
    border-radius: 8px;
    margin-bottom: 12px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1rem;
}

.membership-history-item:hover {
    background: #eaeaea;
    transform: translateY(-2px);
    transition: all 0.3s ease;
}

/* Form Group */
.membership-history-form-group {
    margin-bottom: 15px;
}

/* Inputs, Selects, Textareas */
.membership-history-input,
.membership-history-select,
.membership-history-textarea {
    width: 100%;
    padding: 10px;
    margin-top: 5px;
    border: 1px solid #cccccc;
    border-radius: 8px;
    font-size: 1rem;
    transition: border-color 0.3s ease;
}

.membership-history-input:focus,
.membership-history-select:focus,
.membership-history-textarea:focus {
    border-color: #888888;
    outline: none;
    box-shadow: 0 0 6px rgba(136, 136, 136, 0.4);
}

.membership-history-error-field {
    border: 1px solid red !important;
}

.membership-history-error {
    color: red;
    font-size: 0.9em;
    margin-top: 5px;
}

/* Buttons */
.membership-history-save-btn {
    background: #333333;
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    margin-top: 10px;
    align-self: center;
    font-size: 1rem;
    font-weight: bold;
    text-transform: uppercase;
    transition: background-color 0.3s ease;
}

.membership-history-save-btn:hover {
    background: #555555;
}

.membership-history-edit-btn,
.membership-history-delete-btn {
    background: #007bff;
    color: white;
    border: none;
    padding: 8px 12px;
    border-radius: 6px;
    font-size: 0.9em;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.membership-history-delete-btn {
    background: #f44336;
}

.membership-history-edit-btn:hover {
    background: #0056b3;
}

.membership-history-delete-btn:hover {
    background: #d32f2f;
}

.membership-history-buttons {
    display: flex;
    gap: 10px;
    margin-top: 12px;
    justify-content: flex-end;
}

/* Close Button Styling */
.membership-history-modal-close-btn {
    position: absolute;
    top: 15px;
    right: 15px;
    background: none;
    border: none;
    font-size: 1.5em;
    color: #333333;
    cursor: pointer;
    transition: color 0.3s ease;
}

.membership-history-modal-close-btn:hover {
    color: #555555;
}
