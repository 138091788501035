/* src/components/StatsLodges.css */

.lodges-stats-overlay {
    position: fixed;
    z-index: 9999;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.lodges-stats-content {
    background-color: #fff;
    width: 80%; /* Adjusted width */
    height: 80%; /* Adjusted height */
    border-radius: 8px;
    padding: 20px;
    overflow-y: auto;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.lodges-stats-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ddd;
    padding-bottom: 10px;
}

.lodges-stats-header h2 {
    margin: 0;
    font-size: 1.5rem;
}

.close-btn {
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
}

.lodges-stats-body {
    margin-top: 20px;
    flex: 1;
    overflow-y: auto;
}

/* Summary Cards */
.stats-cards-row {
    display: flex;
    gap: 20px;
    margin-bottom: 20px;
}

.stats-card {
    flex: 1;
    background-color: #f5f5f5;
    padding: 15px;
    border-radius: 6px;
    text-align: center;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.stats-card h3 {
    margin-bottom: 10px;
    font-size: 1rem;
    color: #333;
}

.stats-card p {
    font-size: 1.5rem;
    margin: 0;
    font-weight: bold;
}

/* Charts Section */
.chart-row {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    margin-bottom: 30px;
}

.chart-container, .chart-container-full {
    flex: 1;
    min-width: 300px;
    background-color: #fafafa;
    border-radius: 6px;
    padding: 10px;
    position: relative;
    height: 400px; /* Adjust as needed */
}

.chart-container-full {
    width: 100%;
}

/* TABLE: Location moves by lodge */
.location-moves-section {
    margin-bottom: 30px;
}

.location-moves-section h3 {
    margin-bottom: 10px;
    font-size: 1.25rem;
    color: #333;
}

.simple-table {
    width: 100%;
    border-collapse: collapse;
    font-size: 0.9rem;
}

.simple-table th, .simple-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: center;
    color: #333;
}

.simple-table th {
    background-color: #f2f2f2;
    font-weight: bold;
}

.simple-table tr:nth-child(even) {
    background-color: #f9f9f9;
}

.simple-table tr:hover {
    background-color: #ddd;
}

/* Footer */
.lodges-stats-footer {
    text-align: right;
    margin-top: 10px;
}
