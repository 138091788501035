.analytics-page {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
}

.analytics-page h1 {
    text-align: center;
    margin-bottom: 2rem;
}

/* Container that holds each chart element */
.chart-container {
    margin: 2rem 0;
    width: 100%;
    /* Extra styling if needed */
}

/* A wrapper that sets a consistent height so the chart doesn't blow up */
.chart-wrapper {
    position: relative;
    height: 400px; /* or 300px, or any desired size */
    /* You can also set width: 100% here or leave it flexible */
    width: 100%;
    margin: 0 auto;
}

/* For the pie chart specifically, you might want a smaller max width. */
.chart-container.pie-chart .chart-wrapper {
    max-width: 500px; /* or 400px to keep it smaller */
    height: 400px;    /* keep enough height so the legend fits */
    margin: 0 auto;
}

/* Similarly, you could style line/bar differently if desired */
.chart-container.line-chart .chart-wrapper {
    max-width: 800px; /* e.g. limit if needed */
}
.chart-container.bar-chart .chart-wrapper {
    max-width: 800px;
}
