/* Instructions Page Styles */
.instructions-page {
    font-family: 'Roboto', sans-serif;
    max-width: 900px;
    margin: 20px auto;
    padding: 20px;
    background: #f7f7f7;
    border-radius: 10px;
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.1);
    color: #444;
    line-height: 1.6;
}

.instructions-page h1 {
    font-size: 2.5rem;
    text-align: center;
    color: #333;
    margin-bottom: 30px;
    border-bottom: 3px solid #ccc;
    padding-bottom: 10px;
}

/* Instruction Section Styles */
.instruction-section {
    margin-bottom: 25px;
}

.section-toggle {
    background-color: #e0e0e0;
    color: #333;
    padding: 15px 20px;
    border: none;
    font-size: 1.2rem;
    font-weight: bold;
    border-radius: 8px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s ease, color 0.3s ease;
}

.section-toggle:hover {
    background-color: #d6d6d6;
    color: #111;
}

.section-content {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    margin-top: 10px;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
    animation: fadeIn 0.3s ease-in-out;
}

.section-content h2 {
    font-size: 1.8rem;
    color: #333;
    margin-bottom: 15px;
    border-bottom: 2px solid #ccc;
    padding-bottom: 5px;
}

.section-content p {
    margin: 10px 0;
    color: #555;
    font-size: 1rem;
}

.section-content ul,
.section-content ol {
    margin: 15px 0;
    padding-left: 25px;
    color: #555;
    font-size: 1rem;
}

.section-content li {
    margin-bottom: 8px;
}

.subsection {
    margin-top: 15px;
}

.subsection-toggle {
    background-color: #e6e6e6;
    color: #333;
    padding: 12px 18px;
    border: none;
    font-size: 1.1rem;
    font-weight: bold;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    transition: background-color 0.3s ease, color 0.3s ease;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.subsection-toggle:hover {
    background-color: #d9d9d9;
}

.subsection-content {
    background: #f9f9f9;
    padding: 15px;
    border-radius: 5px;
    margin-top: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
    animation: fadeIn 0.3s ease-in-out;
}

.subsection-content h3, .subsection-content h4 {
    color: #333;
    margin-bottom: 10px;
    font-size: 1.3rem;
}

.subsection-content ul, .subsection-content ol {
    margin: 10px 0;
    padding-left: 20px;
    color: #555;
    font-size: 1rem;
}

.subsection-content li {
    margin-bottom: 8px;
}

.subsection-content p {
    margin: 10px 0;
    color: #555;
}


/* Sub-Subsection Styles */
.sub-subsection {
    margin-top: 10px;
}

.sub-subsection-toggle {
    background-color: #f0f0f0;
    color: #333;
    padding: 10px 16px;
    border: none;
    font-size: 1rem;
    font-weight: bold;
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    transition: background-color 0.3s ease, color 0.3s ease;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
}

.sub-subsection-toggle:hover {
    background-color: #e0e0e0;
}

.sub-subsection-content {
    background: #fafafa;
    padding: 12px;
    border-radius: 4px;
    margin-top: 6px;
    box-shadow: 0 1px 6px rgba(0, 0, 0, 0.05);
    animation: fadeIn 0.3s ease-in-out;
}

.sub-subsection-content h4 {
    color: #333;
    margin-bottom: 8px;
    font-size: 1.2rem;
}

.sub-subsection-content p {
    margin: 8px 0;
    color: #555;
    font-size: 0.95rem;
}

.sub-subsection-content ul, .sub-subsection-content ol {
    margin: 8px 0;
    padding-left: 18px;
    color: #555;
    font-size: 0.95rem;
}

.sub-subsection-content li {
    margin-bottom: 6px;
}

/* Fade-in Animation */
@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(-10px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

/* Media Queries for Responsive Design */
@media (max-width: 768px) {
    .instructions-page {
        padding: 15px;
    }

    .instructions-page h1 {
        font-size: 2rem;
    }

    .section-toggle {
        font-size: 1rem;
        padding: 12px 15px;
    }

    .section-content {
        padding: 15px;
    }

    .section-content h2 {
        font-size: 1.5rem;
    }
}
