/* Minimal example: override react-big-calendar defaults as you like */

/* The main container */
.calendar-page {
    max-width: 1100px;
    margin: 0 auto;
    background-color: #f8f9fa;
    padding: 20px;
    font-family: "Helvetica Neue", Arial, sans-serif;
}

/* Title styling */
.calendar-page h1 {
    text-align: center;
    margin-bottom: 2rem;
    color: #343a40;
}

/* Big Calendar Container */
.big-calendar-container {
    background-color: #fff;
    padding: 1rem;
    border-radius: 5px;
    margin-bottom: 2rem;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

/* The form container */
.add-event-form {
    background-color: #ffffff;
    padding: 1.5rem;
    border-radius: 5px;
    margin-bottom: 2rem;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.add-event-form h2 {
    margin-bottom: 1rem;
    color: #343a40;
}

.form-group {
    margin-bottom: 1rem;
}

.form-group label {
    display: block;
    margin-bottom: 0.4rem;
    font-weight: 600;
    color: #495057;
}

.form-group input[type="datetime-local"],
.form-group textarea {
    width: 100%;
    box-sizing: border-box;
    padding: 0.5rem;
    font-size: 1rem;
    border: 1px solid #ced4da;
    border-radius: 4px;
}

.add-event-form button {
    background-color: #007bff;
    color: #ffffff;
    padding: 0.6rem 1.2rem;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
    font-weight: 600;
    margin-right: 0.5rem;
}

.add-event-form button:hover {
    background-color: #0056b3;
}

/* React Modal overrides */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
}

.modal-content {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 500px;
    max-width: 90%;
    transform: translate(-50%, -50%);
    background: #fff;
    padding: 1.5rem;
    border-radius: 8px;
    outline: none;
}

.modal-content h2 {
    margin-top: 0;
}

.delete-button {
    background-color: #dc3545; /* red */
    color: #ffffff;
}

.delete-button:hover {
    background-color: #c82333;
}

/* Makes the event's text smaller and allows wrapping */
.rbc-event-label,
.rbc-event-content {
    font-size: 0.8rem;        /* Adjust as needed */
    white-space: normal;      /* Allows line breaks */
    word-wrap: break-word;    /* Breaks long words onto next line if needed */
    line-height: 1.2;         /* Tweak if you want tighter or looser spacing */
}

/* Optionally, if you want the event box a bit taller or more padded: */
.rbc-event {
    padding: 2px 4px; /* reduce or increase padding as you like */
}

/* Fix alignment for the Agenda view */
.rbc-agenda-view .rbc-agenda-table {
    table-layout: fixed;
    width: 100%;
}

/* Hide the header row in the Agenda view */
.rbc-agenda-view .rbc-agenda-table thead {
    display: none !important;
}
