body {
  margin: 0;
  font-family: 'Times New Roman', Times, serif; /* Apply Times New Roman globally */
  font-size: 16px; /* Optional: Set a default font size */
  line-height: 1.6; /* Optional: Improve readability */
  color: #333; /* Optional: Default text color */
  background-color: #f9f9f9; /* Optional: Default background */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}
