/* General Layout */
.layout {
    font-family: 'Merriweather', serif;
    background-color: #f9f9f9;
    color: #333;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

.layout-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    background-color: #2c3e50;
    color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    flex-wrap: nowrap;
}

/* Logo Section */
.layout-logo-container {
    flex: 0 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    cursor: pointer;
}

.layout-logo {
    width: 175px;
    height: auto;
}

/* Title and Subtitle Section */
.layout-title-container {
    flex: 2;
    text-align: center;
    padding: 0 20px;
}

.layout-title {
    color: #D7C6A3;
    margin: 0;
    font-size: 1.8rem;
    font-weight: bold;
    word-wrap: break-word; /* Allow long titles to wrap */
    overflow-wrap: break-word; /* Ensure breaking on long words */
}

/* Subtitle Styling */
.layout-subtitle {
    margin: 10px 0 0;
    font-size: 1rem; /* Slightly larger font size */
    font-weight: 300;
    color: #D7C6A3;
    line-height: 1.8; /* Improve spacing between lines */
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    word-wrap: break-word;
}

/* Buttons Section */
.layout-buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}

/* Home Button */
.home-button {
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    outline: none;
}

.home-icon {
    width: 50px;
    height: 50px;
    object-fit: contain;
}

.home-button:hover {
    transform: scale(1.2);
}

/* User Controls */
.logout-button,
.login-button {
    background-color: white;
    color: black;
    border: none;
    padding: 10px 15px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 0.75rem;
    text-align: center;
    transition: background-color 0.3s ease;
    width: 100px;
}

.logout-button:hover,
.login-button:hover {
    background-color: #2980b9;
}

/* Content Section */
.layout-content {
    flex: 1;
    padding: 20px;
}

/* Media Queries for Responsiveness */
@media (max-width: 768px) {
    .layout-header {
        flex-wrap: wrap;
        padding: 10px;
    }

    .layout-logo-container {
        flex-basis: 100%;
        justify-content: center;
        margin-bottom: 10px;
    }

    .layout-logo {
        width: 120px;
    }

    .layout-title-container {
        flex-basis: 100%;
        text-align: center;
    }

    .layout-title {
        font-size: 1.5rem; /* Slightly smaller font for mobile */
        white-space: normal; /* Allow wrapping */
    }

    .layout-subtitle {
        font-size: 0.9rem;
        max-width: 90%;
        margin: 0 auto;
        line-height: 1.5;
    }

    .layout-buttons {
        flex-basis: 100%;
        flex-direction: column;
        justify-content: center;
        margin-top: 10px;
    }

    .home-button,
    .logout-button,
    .login-button {
        width: auto;
        font-size: 0.8rem;
    }
}
